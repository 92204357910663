<template>
  <div class="card mt-4 ml-5 mr-5" style="min-height: 70vh;">
    <h1 class="subtitle is-3 mt-4 ml-5 mr-5 mb-0">
      <b-icon icon="account-circle" size="is-medium" type="is-primary" class="mr-2"></b-icon>{{t('common.hello')}}
      <span class="has-text-primary has-text-weight-bold">{{ fullName }}</span>!
    </h1>
    <div class="m-0 columns">
      <div class="column">
        <div class="pl-3 pr-3 pt-5 pb-3" v-if="session">
          <template v-if="isStudent || isTeacher">
            <table class="table is-bordered is-fullwidth">
              <tr>
                <td class="has-text-right pr-3" style="width: 9.2rem;">{{t('common.academy')}}:</td>
                <td class="has-text-weight-semibold">{{ session.academy }}</td>
              </tr>
              <tr>
                <td class="has-text-right pr-3">{{t('common.courses')}}:</td>
                <td class="has-text-weight-semibold">
                  <b-tag
                    rounded
                    size="is-medium"
                    type="is-primary"
                    v-for="course in myCourses"
                    :key="course.id"
                  >{{ course.name }}</b-tag>
                </td>
              </tr>
            </table>
          </template>

          <template v-if="isStudent">
            <table class="table is-bordered is-fullwidth">
              <tr>
                <td class="has-text-right pr-3" style="width: 9.2rem;">{{t('common.studentIndex')}}:</td>
                <td class="has-text-weight-semibold">{{ session.studentIndex }}</td>
              </tr>
              <tr>
                <td class="has-text-right pr-3" style="width: 9.2rem;">{{t('common.courses')}}:</td>
                <td class="has-text-weight-semibold">{{ session.studentStudy }}</td>
              </tr>
              <tr>
                <td class="has-text-right pr-3" style="width: 9.2rem;">{{t('common.specialization')}}:</td>
                <td class="has-text-weight-semibold">{{ session.studentSpec }}</td>
              </tr>
              <tr>
                <td class="has-text-right pr-3" style="width: 9.2rem;">{{t('common.semester')}}:</td>
                <td class="has-text-weight-semibold">{{ session.studentSemester }}</td>
              </tr>
              <tr>
                <td class="has-text-right pr-3">{{t('common.groups')}}:</td>
                <td class="has-text-weight-semibold">
                  <b-tag
                    rounded
                    size="is-medium"
                    type="is-primary"
                    v-for="group in session.studentGroups"
                    :key="group"
                  >{{ group }}</b-tag>
                </td>
              </tr>
            </table>
          </template>

          <template v-if="isTeacher">
            <table class="table is-bordered is-fullwidth">
              <tr>
                <td class="has-text-right pr-3" style="width: 9.2rem;">{{t('common.teacherId')}}:</td>
                <td class="has-text-weight-semibold">{{ session.teacherId }}</td>
              </tr>
              <tr>
                <td class="has-text-right pr-3" style="width: 9.2rem;">{{t('common.address')}}:</td>
                <td class="has-text-weight-semibold">{{ session.teacherAddress }}</td>
              </tr>
              <tr>
                <td class="has-text-right pr-3">{{t('common.groups')}}:</td>
                <td class="has-text-weight-semibold">
                  <b-tag
                    rounded
                    size="is-medium"
                    type="is-primary"
                    v-for="group in session.teacherGroups"
                    :key="group"
                  >{{ group }}</b-tag>
                </td>
              </tr>
            </table>
          </template>
        </div>
      </div>
      <div class="column">
        <table class="table is-bordered is-fullwidth mt-5">
          <tr>
            <td class="has-text-right pr-3" style="width: 9.2rem;">{{t('common.email')}}</td>
            <td class="has-text-weight-semibold">{{ session.email }}</td>
          </tr>
          <tr>
            <td class="has-text-right pr-3">{{t('common.roles')}}:</td>
            <td class="has-text-weight-semibold">
              <b-tag
                class="mt-1"
                rounded
                size="is-medium"
                type="is-secondary"
              >{{mGetRoleTypesName(myRoles)}}</b-tag>
            </td>
          </tr>
        </table>
      </div>
      <div class="column is-narrow">
        <p class="pl-5 pr-5 pb-5 mb-5 mr-5 ml-5 has-text-centered mb-2">
          <img src="logo.svg" style="width: 14.4rem;" />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import RoleHelpers from "@/mixins/roles"
import { Action } from '../../store/config_actions';

export default {
  name: "Dashboard",
  mixins: [CommonHelpers, RoleHelpers],
  components: {},

  props: {},

  data: function () {
    return {
      myCourses: [],
    };
  },

  mounted() {
    if (this.myIdCourses) {
      this.getMultiCourse();
    }
  },

  watch: {},

  computed: {
    session() {
      return this.$store.state.auth.session;
    },

    fullName() {
      if (this.session) {
        return this.session.firstName + " " + this.session.lastName;
      }
      else return ""
    },

    isAdmin() {
      if (this.session && this.session.roles.includes("Admin")) return true;
      else return false;
    },

    myRoles() {
      if (this.session && this.session.roles) return this.session.roles;
      else return [];
    },

    isTeacher() {
      if (this.session && this.session.roles.includes("Teacher")) return true;
      else return false;
    },

    isStudent() {
      if (this.session && this.session.roles.includes("Student")) return true;
      else return false;
    },

    myIdCourses() {
      if (this.isTeacher) {
        return this.session.teacherCourses;
      } else if (this.isStudent) {
        return this.session.studentCourses;
      } else {
        return null;
      }
    },
  },

  methods: {
    /**
     * Get multiple courses
     */
    getMultiCourse() {
      if (this.myIdCourses) {
        this.isLoading = true;
        let send = { included: this.myIdCourses };
        this.$store
          .dispatch(Action.COURSE_GET_MULTIPLE, send)
          .then((payload) => {
            this.myCourses = payload;
            this.isLoading = false;
          })
          .catch((error) => {
            this.mDangerSnack(error.toString());
            this.isLoading = false;
          });
      }
    },
  },
};
</script>

<style scoped>
</style>